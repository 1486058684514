import {useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {setPlayerInfo, setPlayerState} from '../actions/playerAction';
import {getNearNumber, getNextTopNumber, getTrackInfo} from '../Components/MusicItemPreview/MusicItemPreview';
import {IAppStore, ICategory, IContentInfo} from '../types/types';

type IUseAudio = [
    HTMLAudioElement,
    () => void,
    () => void
];

export const useAudio = (): IUseAudio => {

    const [audio] = useState(new Audio());

    const state: IAppStore = useSelector((st: IAppStore) => st);

    const dispatch = useDispatch();

    const {
        playerInfo: {
            trackNumber,
            topNumber,
            isPlaying,
            musicAlias,
        },
        section: {
            musicCategories,
            musicTops,
        },
    } = state;

    const currentMusicCategory: ICategory = musicCategories[topNumber];
    const playlist: IContentInfo[] | [] = (currentMusicCategory && currentMusicCategory.contents.length)
        ? currentMusicCategory.contents : [];

    const { trackLink } = getTrackInfo(playlist, trackNumber);

    const handlePlay = () => {
        audio.play()
            .then(() => {
                dispatch(setPlayerState({
                    isPlaying: true,
                }));
            })
            .catch(() => {})
    }

    const handlePause = () => {
        audio.pause();
        dispatch(setPlayerState({
            isPlaying: false,
        }));
    }

    useEffect(() => {
        if (trackLink && isPlaying) {
            setImmediate(handlePlay);
        }
    }, [trackLink, trackNumber, topNumber, isPlaying])

    useEffect(() => {
        if (trackLink) {
            const source = document.createElement('source');

            source.setAttribute('src', trackLink);
            source.setAttribute('type', 'audio/mpeg');
            audio.innerHTML = '';
            audio.append(source);
            audio.load();
            audio.preload = 'auto';
        }

        const endedListener = () => {
            handlePlay();
            if (trackNumber === getNearNumber(trackNumber, playlist.length, 1)) {
                const nextTopNumber = getNextTopNumber(topNumber, musicTops);
                const nextMusicAlias = musicTops[nextTopNumber].alias;
                dispatch(setPlayerInfo({
                    trackNumber: 0,
                    musicAlias,
                    topNumber: nextTopNumber,
                }));
            } else {
                dispatch(setPlayerInfo({
                    trackNumber: getNearNumber(trackNumber, playlist.length, 1),
                    musicAlias,
                    topNumber,
                }))
            }
        };

        audio.addEventListener('ended', endedListener);

        return () => {
            audio.removeEventListener('ended', endedListener);
        };
    }, [audio, trackLink]);

    useEffect(() => {
        return () => {
            audio.src = ''
        }
    }, [])

    return [audio, handlePlay, handlePause];
}

import {PRESS_ALIAS} from '../constants/constants';

export function getCategoryButtonText(alias: string) {
    switch (alias) {
        case 'pressa-ru-ua-pdf':
            return 'Все журналы';
        case 'pro-sport':
            return 'Всё о спорте';
        case 'pro-avtomobili':
            return 'Всё о авто';
        case 'pro-stil':
            return 'Всё о стиле';
        case 'pro-gadzhety':
            return 'Всё о гаджетах';
        case 'pro-otdykh':
            return 'Всё об отдыхе';
        case 'pro-kosmos':
            return 'Всё о космосе';
        case 'cookery':
            return 'Всё о питании';
        case 'diet':
            return 'Диета';
        case PRESS_ALIAS:
            return 'Все журналы';
    }
}

import * as Cookies from 'js-cookie';
import {ActionsObservable, combineEpics, ofType, StateObservable} from 'redux-observable';
import {Observable} from 'rxjs/internal/Observable';
import {EMPTY} from 'rxjs/internal/observable/empty';
import {from} from 'rxjs/internal/observable/from';
import {of} from 'rxjs/internal/observable/of';
import {map, mergeMap, mergeMapTo, tap} from 'rxjs/operators';

import {
    CategoriesAction,
    ILoadCategoriesFailAction,
    ILoadCategoriesFinishAction,
    ILoadCategoriesStartAction,
    LOAD_CATEGORIES_FAIL,
    LOAD_CATEGORIES_FINISH,
    LOAD_CATEGORIES_START,
} from '../actions/categoriesAction';
import {
    CHECK_AUTHORIZATION,
    ICheckAuthorizationAction,
} from '../actions/checkAuthorizationtAction';
import {
    ContentAction,
    ILoadContentFailAction,
    ILoadContentFinishAction,
    ILoadContentStartAction,
    LOAD_CONTENT_FAIL, LOAD_CONTENT_FINISH, LOAD_CONTENT_START,
} from '../actions/contentAction';
import {
    FooterAction, ILoadFooterFailAction,
    ILoadFooterFinishAction,
    ILoadFooterStartAction, LOAD_FOOTER_FAIL, LOAD_FOOTER_FINISH,
    LOAD_FOOTER_START,
} from '../actions/footerAction';
import {
    CHANGE_LANGUAGE_FINISH,
    CHANGE_LANGUAGE_START,
    IChangeLanguageStartAction,
    LanguageAction,
} from '../actions/languageAction';
import {
    ILoadOfferFailAction,
    ILoadOfferFinishAction,
    ILoadOfferStartAction, LOAD_OFFER_FAIL, LOAD_OFFER_FINISH,
    LOAD_OFFER_START,
    OfferAction
} from '../actions/offerAction';
import {
    ILoadManifestFailAction,
    ILoadManifestFinishAction,
    ILoadManifestStartAction,
    LOAD_MANIFEST_START, loadManifestFail, loadManifestFinish,
    ManifestAction,
} from '../actions/pressContentAction';
import {
    ILoadPrivacyFailAction,
    ILoadPrivacyFinishAction,
    ILoadPrivacyStartAction,
    LOAD_PRIVACY_FAIL, LOAD_PRIVACY_FINISH,
    LOAD_PRIVACY_START,
    PrivacyAction
} from '../actions/privacyAction';
import {
    ILoadSubscriptionFailAction,
    ILoadSubscriptionFinishAction,
    ILoadSubscriptionStartAction, LOAD_SUBSCRIPTION_FAIL, LOAD_SUBSCRIPTION_FINISH,
    LOAD_SUBSCRIPTION_START,
    SubscriptionAction,
} from '../actions/subscriptionAction';
import {
    ILoadSupportFailAction,
    ILoadSupportFinishAction,
    ILoadSupportStartAction,
    LOAD_SUPPORT_FAIL,
    LOAD_SUPPORT_FINISH,
    LOAD_SUPPORT_START,
    SupportAction
} from '../actions/supportAction';
import {
    getData, getManifest,
    getText,
    IDataResponse, IManifestResponse,
    ITextResponse,
} from '../api';
import {
    AUTH_LINK_RU,
    CATEGORIES_URL,
    FOOTER_URL, OFFER_URL_BASE, PRIVACY_URL_BASE,
    SUBSCRIPTION_URL, SUPPORT_URL,
    TEXTCONTENT_URL_BASE,
} from '../constants/constants'
import {getCurrentLang, setLang} from '../helpers/currentLanguageHelper';
import {IAppStore, ICategory, IContentInfo, IMusicTop, Languages} from '../types/types';

const loadCategoriesEpic = (action$: ActionsObservable<CategoriesAction>, state$: StateObservable<IAppStore>) =>
    action$.pipe(
    ofType<CategoriesAction, ILoadCategoriesStartAction>(LOAD_CATEGORIES_START),
    mergeMap((a: ILoadCategoriesStartAction) => {
        const lang = a.payload;

        const categoriesUrl = `${CATEGORIES_URL}?lang=ru`;

        return from(getData(categoriesUrl)).pipe(
            map((data: IDataResponse): ILoadCategoriesFinishAction | ILoadCategoriesFailAction | undefined => {
                if (data.status >= 400) {
                    return {
                        type: LOAD_CATEGORIES_FAIL,
                        payload: data.status,
                        error: true,
                    };
                }

                if (data.json) {
                    const {name, authorized, status, categories} = data.json;

                    const categoriesMusicData: ICategory[] = categories
                        .filter((category: ICategory) => category.contents
                        .filter((content: IContentInfo) => content.meta_params
                            && content.meta_params.content_type_id === 5544).length)
                        .map((category: ICategory) => {
                            const musicContent: IContentInfo[] = category.contents
                                .filter((content: IContentInfo) => content.meta_params
                                && content.meta_params.content_type_id === 5544);
                            return {
                                ...category,
                                contents: musicContent,
                            }
                        });

                    const categoriesWithoutMusic = categories.map((category: ICategory) => {
                        if (category.contents
                            .filter((content: IContentInfo) => content.meta_params
                                && content.meta_params.content_type_id === 5544).length) {
                            const contentWithoutMusic: IContentInfo[] = category.contents
                                .filter((content: IContentInfo) => !content.meta_params
                                    || content.meta_params.content_type_id !== 5544);
                            return {
                                ...category,
                                contents: contentWithoutMusic,
                            }
                        }
                        return category;
                    });

                    const musicTops: IMusicTop[] | [] = (categoriesMusicData && categoriesMusicData.length)
                        ? categoriesMusicData.map((category: ICategory) => {
                        return { alias: category.alias };
                    }) : [];

                    return {
                        type: LOAD_CATEGORIES_FINISH,
                        payload: {
                            name,
                            authorized,
                            status,
                            categories: categoriesWithoutMusic,
                            musicCategories: categoriesMusicData,
                            musicTops,
                        },
                        lang,
                    }
                }
            }),
        )
    }),
);

const loadManifestEpic = (action$: ActionsObservable<ManifestAction>, state$: StateObservable<IAppStore>) =>
    action$.pipe(
        ofType<ManifestAction, ILoadManifestStartAction>(LOAD_MANIFEST_START),
        mergeMap((a: ILoadManifestStartAction) => {
            const hash = a.payload.hash;
            Cookies.set('lastUrl', a.payload.routeUrl);

            const manifestUrl = TEXTCONTENT_URL_BASE + a.payload.hash + '/manifest.json';

            if (!state$.value.section.authorized) {
                document.location.href = AUTH_LINK_RU;
                return EMPTY;
            }

            return from(getManifest(manifestUrl)).pipe(
                mergeMap((data: IManifestResponse)
                    : Observable<ILoadManifestFinishAction> | Observable<ILoadManifestFailAction> => {
                    if ((data.status === 401) || (data.status === 403) || (data.status === 418)) {
                        document.location.href = AUTH_LINK_RU;
                    }

                    if ((data.status >= 400) && (data.status !== 403)
                        && (data.status !== 401) && (data.status !== 418)) {
                        return of(loadManifestFail(data.status));
                    }

                    if (data.json) {
                        return of(loadManifestFinish(data.json, hash));
                    }
                    return EMPTY;
                }),
            )
        }),
    );

const loadContentEpic = (action$: ActionsObservable<ContentAction>, state$: StateObservable<IAppStore>) =>
    action$.pipe(
        ofType<ContentAction, ILoadContentStartAction>(LOAD_CONTENT_START),
        mergeMap((a: ILoadContentStartAction) => {
            const hash = a.payload.hash;
            Cookies.set('lastUrl', a.payload.routeUrl);
            const contentUrl = TEXTCONTENT_URL_BASE + a.payload.hash;

            return from(getText(contentUrl)).pipe(
                map((data: ITextResponse): ILoadContentFinishAction | ILoadContentFailAction | undefined => {
                    if ((data.status === 401) || (data.status === 403) || (data.status === 418)) {
                        document.location.href = AUTH_LINK_RU;
                    }

                    if ((data.status >= 400) && (data.status !== 403)
                        && (data.status !== 401) && (data.status !== 418)) {
                        return {
                            type: LOAD_CONTENT_FAIL,
                            payload: data.status,
                            textError: true,
                        }
                    }

                    if (data.text) {
                        return {
                            type: LOAD_CONTENT_FINISH,
                            hash,
                            text: data.text,
                        }
                    }
                }),
            )
        }),
    );

const loadSubscriptionEpic = (action: ActionsObservable<SubscriptionAction>, state$: StateObservable<IAppStore>) =>
    action.pipe(
        ofType<SubscriptionAction, ILoadSubscriptionStartAction>(LOAD_SUBSCRIPTION_START),
        mergeMap((a: ILoadSubscriptionStartAction) => {
            const lang = a.payload.lang;

            return from(getText(SUBSCRIPTION_URL)).pipe (
                map((data: ITextResponse): ILoadSubscriptionFinishAction | ILoadSubscriptionFailAction | undefined => {
                    if (data.status >= 400) {
                        return {
                            type: LOAD_SUBSCRIPTION_FAIL,
                            payload: data.status,
                            error: true,
                        }
                    }
                    if (data.text) {
                        return {
                            type: LOAD_SUBSCRIPTION_FINISH,
                            lang,
                            text: data.text,
                        }
                    }
                }),
            )
        }),
    );

const loadFooterEpic = (action: ActionsObservable<FooterAction>, state$: StateObservable<IAppStore>) =>
    action.pipe(
        ofType<FooterAction, ILoadFooterStartAction>(LOAD_FOOTER_START),
        mergeMap((a: ILoadFooterStartAction) => {
            const lang = a.payload.lang;
            return from(getText(FOOTER_URL)).pipe (
                map((data: ITextResponse): ILoadFooterFinishAction | ILoadFooterFailAction | undefined => {
                    if (data.status >= 400) {
                        return {
                            type: LOAD_FOOTER_FAIL,
                            payload: data.status,
                            error: true,
                        }
                    }
                    if (data.text) {
                        return {
                            type: LOAD_FOOTER_FINISH,
                            lang,
                            text: data.text,
                        }
                    }
                }),
            )
        }),
    );

const loadOfferEpic = (action: ActionsObservable<OfferAction>, state$: StateObservable<IAppStore>) =>
    action.pipe(
        ofType<OfferAction, ILoadOfferStartAction>(LOAD_OFFER_START),
        mergeMap((a: ILoadOfferStartAction) => {
            const lang = a.payload.lang;
            return from(getText(OFFER_URL_BASE)).pipe (
                map((data: ITextResponse): ILoadOfferFinishAction | ILoadOfferFailAction | undefined => {
                    if (data.status >= 400) {
                        return {
                            type: LOAD_OFFER_FAIL,
                            payload: data.status,
                            error: true,
                        }
                    }
                    if (data.text) {
                        return {
                            type: LOAD_OFFER_FINISH,
                            lang,
                            text: data.text,
                        }
                    }
                }),
            )
        }),
    );

const loadPrivacyEpic = (action: ActionsObservable<PrivacyAction>, state$: StateObservable<IAppStore>) =>
    action.pipe(
        ofType<PrivacyAction, ILoadPrivacyStartAction>(LOAD_PRIVACY_START),
        mergeMap((a: ILoadPrivacyStartAction) => {
            const lang = a.payload.lang;
            return from(getText(PRIVACY_URL_BASE)).pipe (
                map((data: ITextResponse): ILoadPrivacyFinishAction | ILoadPrivacyFailAction | undefined => {
                    if (data.status >= 400) {
                        return {
                            type: LOAD_PRIVACY_FAIL,
                            payload: data.status,
                            error: true,
                        }
                    }
                    if (data.text) {
                        return {
                            type: LOAD_PRIVACY_FINISH,
                            lang,
                            text: data.text,
                        }
                    }
                }),
            )
        }),
    );

const loadSupportEpic = (action: ActionsObservable<SupportAction>, state$: StateObservable<IAppStore>) =>
    action.pipe(
        ofType<SupportAction, ILoadSupportStartAction>(LOAD_SUPPORT_START),
        mergeMap((a: ILoadSupportStartAction) => {
            const lang = a.payload.lang;
            return from(getText(SUPPORT_URL)).pipe (
                map((data: ITextResponse): ILoadSupportFinishAction | ILoadSupportFailAction | undefined => {
                    if (data.status >= 400) {
                        return {
                            type: LOAD_SUPPORT_FAIL,
                            payload: data.status,
                            error: true,
                        }
                    }
                    if (data.text) {
                        return {
                            type: LOAD_SUPPORT_FINISH,
                            lang,
                            text: data.text,
                        }
                    }
                }),
            )
        }),
    );


const languageEpic = (action: ActionsObservable<LanguageAction>, state$: StateObservable<IAppStore>) =>
    action.pipe(
        ofType<LanguageAction, IChangeLanguageStartAction>(CHANGE_LANGUAGE_START),
        mergeMap((a: IChangeLanguageStartAction) => {
            const lang: Languages = a.payload;
            Cookies.remove('language', { path: '' });
            Cookies.set('language', lang);
            return from(setLang(lang)).pipe (
                map(() => ({
                type: CHANGE_LANGUAGE_FINISH,
                payload: lang,
            })),
            )
        }),
    );

const checkAuthorizationEpic =
    (action: ActionsObservable<ICheckAuthorizationAction>, state$: StateObservable<IAppStore>) =>
    action.pipe(
        ofType<ICheckAuthorizationAction>(CHECK_AUTHORIZATION),
        mergeMap((a: ICheckAuthorizationAction) => {
            return from(getData(CATEGORIES_URL)).pipe(
                tap((data: IDataResponse): void => {
                        if (data.json && !data.json.authorized) {
                            Cookies.set('lastUrl', a.payload.routeUrl);
                            document.location.href = AUTH_LINK_RU;
                        }
                    },
                ),
                mergeMap(() => EMPTY),
            )
        }),
    );

export const rootEpic =
    combineEpics(
        loadCategoriesEpic,
        loadContentEpic,
        loadFooterEpic,
        loadOfferEpic,
        loadSubscriptionEpic,
        checkAuthorizationEpic,
        languageEpic,
        loadManifestEpic,
        loadSupportEpic,
        loadPrivacyEpic,
    );

import {
    AMOUNT_OF_CONTENTITEMS_FOR_MAINPAGE,
    COMMON_CONTENT_CARD_TYPE,
    MUSIC_CONTENT_CARD_TYPE,
    MUSIC_TAG_TYPE,
} from '../constants/constants';
import {ContentType, IContentInfo, ITag} from '../types/types';

import {checkContentProps} from './checkContentProps';

export interface IContentByType {
    [type: string]: IContentInfo[];

}

export interface IContentCard {
    cardType: string;
    data: IContentInfo[];
}

export interface ICards {
    [type: string]: IContentCard[];
}

export const getParamType = (content: IContentInfo): string[] => [content.type];
export const getParamTagName = (content: IContentInfo): string[] => {
    const names: string[] = [];

    content.tags.forEach((tag: ITag) => {
        names.push(tag.display_name);
    });
    return names;
};

export function getContentByType(
    content: IContentInfo[],
    getParam: (content: IContentInfo) => string[],
): IContentByType {
    let parameter;

    return content.reduce<IContentByType>((res, item) => {
        const params: string[] = getParam(item);
        [parameter] = params;

        params.forEach((param: string) => {
            if (res[param] === undefined) {
                res[param] = [];
            }
            res[param].push(item);
        });

        return res;
    }, {});
}

function tryToPush(from: IContentCard[], to: IContentCard[]) {
    const elem = from.shift();
    elem && to.push(elem);
}

function getCard(contents: IContentInfo[], contentType: string): IContentCard {
    return ({
        cardType: contentType,
        data: contents,
    })
}

export function getCards(contentData: IContentInfo[]): ICards {
    const musicContent: IContentInfo[] = [];
    const commonContent: IContentInfo[] = [];

    contentData.forEach((content: IContentInfo) => {
        const isMus = content.tags.filter((tag: ITag) => tag.type.includes(MUSIC_TAG_TYPE));

        if (isMus.length > 0) {
            musicContent.push(content);
        } else {
            commonContent.push(content);
        }
    });

    const checkedContent = commonContent.filter((elem) => {
        return checkContentProps(elem);
    });

    const musicContentByName = getContentByType(musicContent, getParamTagName);

    const contentByType = getContentByType(checkedContent, getParamType);
    const videoContentType1: IContentInfo[] = contentByType[ContentType.type2] || [];
    const videoContentType2: IContentInfo[] = contentByType[ContentType.type4] || [];
    const videoContent = videoContentType1.concat(videoContentType2);
    const textContent: IContentInfo[] = contentByType[ContentType.type1] || [];

    const videoCards: IContentCard[] = videoContent.map((item: IContentInfo) => {
        return getCard([item], 'content');
    });

    const textCards: IContentCard[] = textContent.map((item: IContentInfo) => {
        return getCard([item], COMMON_CONTENT_CARD_TYPE);
    });
    const musicCards: IContentCard[] = [];

    for (const name in musicContentByName) {
        const musicCard = getCard(musicContentByName[name], MUSIC_CONTENT_CARD_TYPE);
        musicCard && musicCards.push(musicCard);
    }

    return ({
        video: videoCards,
        text: textCards,
        music: musicCards,
    })
}

export function getFullCards(contentsData: IContentInfo[]): IContentCard[] {
    const {video, text} = getCards(contentsData);
    const partialCards: IContentCard[] = [];

    if (video[0]) {
        tryToPush(video, partialCards);
        tryToPush(text, partialCards);
        tryToPush(text, partialCards);
        tryToPush(text, partialCards);
        tryToPush(video, partialCards);
        tryToPush(video, partialCards);
        tryToPush(video, partialCards);
    } else {
        tryToPush(text, partialCards);
        tryToPush(text, partialCards);
        tryToPush(text, partialCards);
        tryToPush(text, partialCards);
        tryToPush(text, partialCards);
        tryToPush(text, partialCards);
        tryToPush(text, partialCards);
    }

    while (partialCards.length < AMOUNT_OF_CONTENTITEMS_FOR_MAINPAGE - 1) {
        tryToPush(text, partialCards);
    }

    while (video[0] && text[0]) {
        tryToPush(video, partialCards);
        tryToPush(text, partialCards);
    }

    const fullCards = partialCards.concat(text).concat(video);
    return fullCards;
}

export function getPartialCards(contentsData: IContentInfo[]): IContentCard[] {
    const fullCards = getFullCards(contentsData);
    const removedMusicCards = fullCards.filter((el: IContentCard) => el.cardType != 'music')
    const partialCards = removedMusicCards.slice(0, AMOUNT_OF_CONTENTITEMS_FOR_MAINPAGE);
    return partialCards;
};

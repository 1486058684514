export const LOAD_PRIVACY_START = 'LOAD_PRIVACY_START';
export const LOAD_PRIVACY_FINISH = 'LOAD_PRIVACY_FINISH';
export const LOAD_PRIVACY_FAIL = 'LOAD_PRIVACY_FAIL';

export type LOAD_PRIVACY_START_TYPE = typeof LOAD_PRIVACY_START;
export type LOAD_PRIVACY_FINISH_TYPE = typeof LOAD_PRIVACY_FINISH;
export type LOAD_PRIVACY_FAIL_TYPE = typeof LOAD_PRIVACY_FAIL;

export interface ILoadPrivacyStartAction {
    type: LOAD_PRIVACY_START_TYPE;
    payload: {
        lang: string;
    }
}

export interface ILoadPrivacyFinishAction {
    type: LOAD_PRIVACY_FINISH_TYPE;
    lang: string;
    text: string;
}

export interface ILoadPrivacyFailAction {
    type: LOAD_PRIVACY_FAIL_TYPE;
    payload: number;
    error: boolean;
}

export type PrivacyAction = ILoadPrivacyStartAction | ILoadPrivacyFinishAction | ILoadPrivacyFailAction;

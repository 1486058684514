import React from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import Swiper from 'react-id-swiper';

import {ReactComponent as MaximIcon} from '../../media/icons/maxim-logo.svg';
import promoBanner1 from '../../media/images/promo-banner-1.jpg'
import promoBanner2 from '../../media/images/promo-banner-2.jpg'
import promoBanner3 from '../../media/images/promo-banner-3.jpg'
import promoBanner4 from '../../media/images/promo-banner-4.jpg'
import promoBanner5 from '../../media/images/promo-banner-5.jpg'
import promoBanner6 from '../../media/images/promo-banner-6.jpg'
import promoBanner7 from '../../media/images/promo-banner-7.jpg'

import './PromoBanner.css'

interface IPromoBannerItem {
  id: number;
  image: string;
  containerReverse?: boolean;
  captionReverse?: boolean;
  captionTitleTransparent?: boolean;
  captionTitleSemiTransparent?: boolean;
  captionTitleAbsolute?: boolean;
  captionTitleWithLink?: boolean;
}

interface IPromoBanner {
  items: IPromoBannerItem[];
  className?: string;
}

const promoBanners: IPromoBannerItem[] = [
  {
    id: 1,
    image: promoBanner1,
    containerReverse: true,
  },
  {
    id: 2,
    image: promoBanner2,
    containerReverse: true,
  },
  {
    id: 3,
    image: promoBanner3,
    containerReverse: true,
  },
  {
    id: 4,
    image: promoBanner4,
    containerReverse: true,
  },
  {
    id: 5,
    image: promoBanner5,
    containerReverse: true,
    captionReverse: true,
    captionTitleTransparent: true,
    captionTitleAbsolute: true,
    captionTitleWithLink: true,
  },
  {
    id: 6,
    image: promoBanner6,
    containerReverse: true,
    captionTitleSemiTransparent: true,
    captionTitleAbsolute: true,
    captionTitleWithLink: true,
  },
  {
    id: 7,
    image: promoBanner7,
    containerReverse: true,
    captionTitleSemiTransparent: true,
    captionTitleAbsolute: true,
    captionTitleWithLink: true,
  },
]

export const promoBannersMain: IPromoBannerItem[] = [
  promoBanners[0],
  promoBanners[1],
  promoBanners[2],
  promoBanners[3],
]

export const promoBannersAuto: IPromoBannerItem[] = [
  promoBanners[6],
]

export const promoBannersSport: IPromoBannerItem[] = [
  promoBanners[4],
]

export const promoBannersRelax: IPromoBannerItem[] = [
  promoBanners[5],
]

export function PromoBanner(props: IPromoBanner) {
  const { items = [], className } = props

  const promoBanner: ClassNameFormatter = cn('PromoBanner');
  const promoBannerItem: ClassNameFormatter = cn('PromoBannerItem');

  // Параметры кроме картинок берутся с первого элемента, тк onSlideChange не возвращает realIndex
  // По необходимости нужно доработать
  const firstItem = items[0];

  const url = 'http://m.funzone.by/subslp/flow/m_premium_maxim'
  const onClick = () => window.location.replace(url)

  return (
    <div className={classnames(promoBanner(), className)}>
      <div
        onClick={onClick}
        className={promoBannerItem({Reverse: firstItem.containerReverse})}
      >
        <div
          className={promoBannerItem('Caption', {
            Reverse: firstItem.captionReverse,
            TitleAbsolute: firstItem.captionTitleAbsolute,
            WithLink: firstItem.captionTitleWithLink,
          })}
        >
          <div
            className={promoBannerItem('CaptionTitle', {
              Transparent: firstItem.captionTitleTransparent,
              SemiTransparent: firstItem.captionTitleSemiTransparent,
            })}
          >
            <MaximIcon/>
            {firstItem.captionTitleWithLink ? (
              <div className={promoBannerItem('CaptionTitleLink')}>
                <div className={promoBannerItem('CaptionTitleLinkText')}>Смотреть</div>
              </div>
            ) : null}
          </div>
          <div className={promoBannerItem('CaptionDescription')}>
            Онлайн-журнал
            {' '}
            <br className={promoBannerItem('CaptionDescriptionBr')}/>
            №1 для мужчин
          </div>
        </div>
        <Swiper
          slidesPerView={1}
          autoplay={Array.isArray(items) && items.length > 1}
          loop={Array.isArray(items) && items.length > 1}
          containerClass={promoBanner('Swiper')}
          allowTouchMove={false}
        >
          {items.map((item) => {
            return (
              <img
                src={item.image}
                alt={''}
                className={classnames(promoBannerItem('Image'), 'swiper-slide')}
                key={item.id}
              />
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

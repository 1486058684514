import {IAppStore, IContentInfo} from '../../../types/types';
import {getCurrentLang} from '../../../helpers/currentLanguageHelper';
import {IPressContentItemStateProps, PressContentItem} from './PressContentItem';
import {connect} from 'react-redux';
import {IClassNameProps} from '@bem-react/core';

export interface IOwnProps extends IClassNameProps {
    content: IContentInfo;
}

const mapStateToProps = (store: IAppStore, ownProps: IOwnProps): IPressContentItemStateProps => {
    const currentLanguage = getCurrentLang();
    return {
        ...ownProps,
        language: currentLanguage,
        authorised: store.section.authorized,
    }
};

export const PressContentItemContainer = connect(mapStateToProps, null)(PressContentItem);
import {Languages} from '../types/types';

// ODUzOWJkYzhjMGZlY2NiMQ для local
// OGY3NmFiYzllY2RhMGI1Mw для stage
// Mjg3NmYxMDI5NzM4ODMzZg для stable

export const CATEGORIES_URL: string = '/api/compact-auto';

export const DEFAULT_LANG = Languages.ru;
export const HOME: string = '/';
export const TEXTCONTENT_URL_BASE: string = '/gate/d/';

export const TEXT_URL_BASE: string = '/text/mts-rb-mens-live/';
export const OFFER_URL_BASE: string = TEXT_URL_BASE + 'oferta/1';
export const FOOTER_URL: string = TEXT_URL_BASE + 'footer-main-page/1';
export const SUBSCRIPTION_URL: string = TEXT_URL_BASE + 'privacyfooter/1';
export const PRIVACY_URL_BASE: string = TEXT_URL_BASE + 'privacypolicy/1';
export const SUPPORT_URL: string = TEXT_URL_BASE + 'header-support/1';
export const AUTH_LINK_RU = 'http://m.funzone.by/subslp/flow/m_menslive?source=service';

// aliases
export const PRESS_ALIAS = 'pressa-ru-ua-pdf';
export const MUSIC_TAG_TYPE = 'music_import_';
export const COMMON_CONTENT_CARD_TYPE = 'content';
export const MUSIC_CONTENT_CARD_TYPE = 'music';
export const JOURNALS_TAG_TYPE = 'ji_journal_';
export const RUS_LANG = 'ru';

// numbers constant
export const AMOUNT_OF_CONTENTITEMS_FOR_MAINPAGE = 8;

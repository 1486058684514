export enum PreviewType {
    type1 = 'main',
    type2 = 'middle',
    type3 = 'small',
}

export enum ContentType {
    type1 = 'html',
    type2 = 'sf_section',
    type3 = 'journal',
    type4 = 'sf_any_content',
}

export enum PageType {
    typeMain = 'MainPage',
    typeCategory = 'CategoryPage',
    typePress = 'PressPage',
    typeContent = 'ContentPage',
    typeOffer = 'OfferPage',
    typeSubscription = 'SubscriptionPage',
}

export enum Languages {
    ru = 'ru-RU',
}

export interface IMultiName {
    ru: string;
    am: string;
    en: string;
}

export interface ITag {
    id: number;
    type: string;
    display_name: string;
    priority: number;
}

export interface ICategory {
    id: number;
    name: string;
    alias: string;
    contents: IContentInfo[];
}

export interface IContentInfo {
    description: string;
    id: number;
    name: string;
    type: ContentType;
    tags: ITag[];
    file_hash: string;
    previews: IPreview[];
    uidx: string;
    meta_params?: {
        content_type_id: number,
        fat_name: string,
    }
}

export interface IPreview {
    id: number;
    type: PreviewType;
    link: string;
}

export interface IMappedDataResponse {
    name: string;
    categories: ICategory[];
    musicCategories: ICategory[];
    musicTops: IMusicTop[] | [];
    authorized?: boolean;
    status?: string;
}

export interface IPlayerInfoPayload {
    trackNumber: number;
    musicAlias: string;
    topNumber: number;
}

export interface IPlayerStatePayload {
    isPlaying: boolean;
}

export interface IPlayerInfo {
    trackNumber: number;
    musicAlias: string;
    isPlaying: boolean;
    topNumber: number;
}

export interface ISection {
    categoryLoaded: boolean;
    categories: {
        // todo почему нельзя в тип lang Language?
        [lang: string]: ICategory[] | [] | undefined;
    };
    musicCategories: ICategory[] | [];
    musicTops: IMusicTop[] | [];
    error?: boolean;
    authorized?: boolean;
}

export interface IContentText {
    contentLoaded: boolean;
    contents: {
        [key: string]: string;
    }
    textError?: boolean;
}

export interface IPressContentManifest {
    manifestLoaded: boolean;
    manifests: {
        [key: string]: string[];
    }
    manifestError: boolean;
}

export interface ILoadedText {
    textLoaded: boolean;
    text: {
        [key: string]: string;
    }
    error?: boolean;
}

export interface IMusicTop {
    alias: string;
}

export interface ITrackInfo {
    trackName: string;
    playerName: string;
    previewLink: string;
    trackLink: string;
}

export interface IAppStore {
    section: ISection;
    content: IContentText;
    playerInfo: IPlayerInfo;
    pressContent: IPressContentManifest;
    offerText: ILoadedText;
    footerText: ILoadedText;
    subscriptionText: ILoadedText;
    supportText: ILoadedText;
    lang: Languages;
    privacyText: ILoadedText;
}

import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import Swiper from 'react-id-swiper';
import LazyLoad from 'react-lazyload';

import {IContentInfo} from '../../../types/types';
import {PressContentItemContainer} from '../PressContentItem/PressContentItemContainer';

import './PressContentSlider.css';

const pressContentSlider: ClassNameFormatter = cn('PressContentSlider');

export interface IPressContentSlider extends IClassNameProps {
    pressContent: IContentInfo[];
    categoryAlias: string;
    page: string;
}

export class PressContentSlider extends Component<IPressContentSlider> {
    getContentItems = () => {
        const {pressContent} = this.props;
        return (
            pressContent.map((content: IContentInfo, index: number) => {
                return (
                    <span className={pressContentSlider('ContentList')} key={index}>
                        <PressContentItemContainer content={content}
                                                className={classnames(pressContentSlider('Item'), 'swiper-slide')}
                                key={index} />
                    </span>
                )
            })
        )
    };

    render() {
        let nameOfPage;
        switch (this.props.page) {
            case 'main':
                nameOfPage = 'MainPage';
                break;
            case 'category':
                nameOfPage = 'CategoryPge';
                break;
            default:
                nameOfPage = 'CommonPage';
        }

        const settings = {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: false,
            },
            breakpoints: {
                100: {
                    slidesPerView: 1,
                },
                300: {
                    slidesPerView: 2,
                },
                400: {
                    slidesPerView: 3,
                },
                720: {
                    slidesPerView: 4,
                },
                900: {
                    slidesPerView: 4,
                },
                1000: {
                    slidesPerView: 5,
                },
                1200: {
                    slidesPerView: 6,
                },
                1300: {
                    slidesPerView: 6,
                },
                1500: {
                    slidesPerView: 7,
                },
                1700: {
                    spaceBeetween: 30,
                    slidesPerView: 8,
                },
            },
            lazy: {
                loadPrevNext: true,
            },
        };

        return (
            <div className={pressContentSlider({ofPage: nameOfPage})}>
                <LazyLoad scroll={true}>
                    <Swiper  {...settings}>
                        {this.getContentItems()}
                    </Swiper>
                </LazyLoad>
            </div>
        )
    }
}

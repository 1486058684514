import {ContentItemPreview, IContentItemPreviewStateProps} from './ContentItemPreview';
import {IAppStore} from '../../types/types';
import {connect} from 'react-redux';

const mapStateToProps = (store: IAppStore): IContentItemPreviewStateProps => {
    return {
        authorised: store.section.authorized,
    };
};

export const ContentItemPreviewContainer = connect(mapStateToProps, null)(ContentItemPreview);

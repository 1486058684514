import React, {Component, ReactNode} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import {Link} from 'react-router-dom';

import {getCategoryLink} from '../../helpers/getLinks';
import {ICategory} from '../../types/types';

import './Menu.css';
import {Translation} from 'react-i18next';
import {PRESS_ALIAS} from '../../constants/constants';

export interface IMenuProps extends IClassNameProps {
    categoriesData: ICategory[];
    page: string;
    categoryAlias?: string;
    onClose(): void;
}

const menu: ClassNameFormatter = cn('Menu');

export class Menu extends Component <IMenuProps> {
    getMenuItems = (categoriesData: ICategory[]): ReactNode[] => {
        const newCategoriesList: ICategory[] = [];
        // категория "пресса" д.б. первой
        categoriesData.map((category: ICategory) => {
            if (category.alias === PRESS_ALIAS) {
                newCategoriesList.unshift(category);
            } else {
                newCategoriesList.push(category);
            }
        });
     
        return (
            newCategoriesList.map((category: ICategory, index) => {
                const linkToCategory = getCategoryLink(category.alias);

                return (
                    <li className={menu('Item', {type: category.alias})} key={index}>
                        <Link
                            onClick={this.props.onClose}
                            className={classnames(menu('Link'), menu('ItemLink'))}
                            to={linkToCategory}
                        >
                            <Translation>
                                {
                                    (t) =>
                                    <div className={menu('ItemLink-Text')}>{t(category.name)}</div>
                                }
                            </Translation>
                        </Link>
                    </li>
                )
            })
        )
    };

    render() {
        const {categoriesData, page, categoryAlias} = this.props;

        return (
            <div className={
                classnames(
                    menu(),
                    menu({ofPage: page}),
                    menu({theme: categoryAlias}),
                    this.props.className)}>
                <div className={menu('Wrapper')}>
                    <ul className={menu('List')}>
                        {this.getMenuItems(categoriesData)}
                    </ul>
                </div>
            </div>
        );
    }
}

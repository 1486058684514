import {LOAD_PRIVACY_FAIL, LOAD_PRIVACY_FINISH, LOAD_PRIVACY_START, PrivacyAction} from '../actions/privacyAction';
import {ILoadedText} from '../types/types';

const initialStore: ILoadedText = {
    textLoaded: false,
    text: {},
    error: false,
};

export function privacyReducer(state: ILoadedText = initialStore, action: PrivacyAction): ILoadedText {
    switch (action.type) {
        case LOAD_PRIVACY_START: {
            return {...state, textLoaded: false}
        }
        case LOAD_PRIVACY_FINISH: {
            return {
                ...state,
                textLoaded: true,
                text: {
                    ...state.text,
                    [action.lang]: action.text,
                },
            }
        }
        case LOAD_PRIVACY_FAIL: {
            return {...state, textLoaded: false, error: action.error}
        }
    }
    return state;
}

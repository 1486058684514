import {IClassNameProps} from '@bem-react/core';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {LOAD_PRIVACY_START} from '../../actions/privacyAction';
import {IAppStore, ICategory, Languages} from '../../types/types';

import {IPrivacyPageStateProps, PrivacyPage} from './PrivacyPage';

export interface IOwnProps extends IClassNameProps {
    categoriesData?: ICategory[];
}

const mapStateToProps = (store: IAppStore, ownProps: IOwnProps): IPrivacyPageStateProps => {
    return {
        privacyText: store.privacyText.text[store.lang],
        privacyTexts: store.privacyText.text,
        error: store.privacyText.error,
        textLoaded: store.privacyText.textLoaded,
        language: store.lang,
    }
};

const mapDispatchToProps = (dispatch: Dispatch): {} => ({
    onLoad: (lang: Languages) => {
        dispatch({type: LOAD_PRIVACY_START, payload: {
            lang,
            }})
    },
});

export const PrivacyPageContainer = connect(mapStateToProps, mapDispatchToProps)(PrivacyPage);
